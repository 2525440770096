@use "src/styles/vars" as *;
@use "src/styles/mixins" as *;

.container {
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  margin-top: 1rem;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &.noBackBtn {
    margin-top: 3rem;
  }

  @include for-phone-only {
    &.noBackBtn {
      margin-top: 2rem;
    }
  }
}
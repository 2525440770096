@use "src/styles/vars" as *;
@use "src/styles/animations" as *;

.slideInLeft {
    @include slide-in-animation(-25px, "horizontal");
}

.slideInRight {
    @include slide-in-animation(25px, "horizontal");
}

.itemPanel {
    svg {
        cursor: pointer;
        height: 1.5rem;
        width: 1.5rem;
    }

    .warningCodeParent {
        flex-grow: 1;
        margin-bottom: 10px;
        cursor: pointer;
        font-size: 12px;
        padding: 5px 10px;
        border-radius: 3px;
        background: $lightGray;
        border: 1px solid $gray2;

        svg {
            opacity: .1;
            height: 1rem;
            width: 1rem;
            transition: all 0.25s;
        }
    }

    .deleteContainer {
        padding-top: 1px;
        color: $red;
        opacity: .1;
        transition: all 0.25s;
    }

    &:hover,
    &.active {
        .deleteContainer {
            opacity: .8;
        }

        .warningCodeParent {
            svg {
                opacity: .8;
            }
        }
    }
}

.toolbar {
    div {

        cursor: pointer;

        &.disabled {
            opacity: .4;
            cursor: not-allowed;
            pointer-events: none !important;
        }

        padding: 5px;
        border-radius: 5px;
        display: flex;
        background-color: $white;
        gap: 0.6rem;

        svg {
            height: 1.2rem;
            width: 1.2rem;
        }
    }
}

.content {
    padding: 20px;
    border-radius: 3px;
    background-color: $white;
    border: 1px solid $gray2;

    .deleteIcon {
        text-align: right;
        padding-top: 25px;

        svg {
            cursor: pointer;
            color: $red;
        }
    }
}

.newWarningCodeWrapper {
    margin: 0;
    padding: 0;
}
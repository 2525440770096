@use "src/styles/vars" as *;
@use "src/styles/animations" as *;

.title {
  @include slide-in-animation(-25px);
}

.box {
  padding: 1.7rem;
  border-radius: 4px;
  min-width: 200px;
  min-height: 140px;
  border: 1px solid $gray2;
  font-size: 1rem;
  letter-spacing: 2px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  align-items: center;
  justify-content: center;
  color: $gray5;
  text-transform: uppercase;
  transition: 0.3s all;
  background-color: $lightGray;

  @include slide-in-animation(25px);

  &:hover {
    box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.1);
  }

  .desc {
    font-size: 0.7rem;
    margin-top: 0.5rem;
    max-width: 190px;
    text-align: center;
    color: $gray4;
    font-weight: 300;
  }

  svg {
    height: 1.8rem;
    width: 1.8rem;
    stroke: $gray3;
  }
}

/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

@use "sass:color";
@use "vars" as *;

.btn {
  background-color: $primary;
  color: $white;
  padding: 0rem 1rem;
  height: 2.4rem;
  min-width: 160px;
  border: none;
  cursor: pointer;
  font-size: 0.75rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  transition: all 0.2s;
  line-height: 1.2rem;
  text-align: left;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-radius: 3px;

  &.ghost {
    background-color: transparent;
    color: $primary;
    border: 2px solid $primary;

    &:hover {
      background-color: color.scale($primary, $lightness: 8%);
      border-color: color.scale($primary, $lightness: 8%);

      .icon {
        opacity: 0.8;

        svg {
          stroke: $white;
        }
      }
    }

    .icon {
      opacity: 0.8;

      svg {
        stroke: $primary;
      }
    }
  }

  &.success {
    background-color: $green;

    &:hover {
      background-color: color.scale($green, $lightness: 8%);
    }
  }

  &.danger {
    background-color: $red;

    &:hover {
      background-color: color.scale($red, $lightness: 8%);
    }
  }

  &:hover {
    background-color: color.scale($primary, $lightness: 8%);
    color: $white;
    text-decoration: none;
  }

  &:active {
    transform: scale(0.99);
    background-color: color.scale($primary, $lightness: -5%);
  }

  .text {
    overflow-wrap: break-word;
  }

  .icon {
    min-width: 22px;
    margin-right: 4px;

    svg {
      width: 20px;
      height: 20px;
      stroke: $white;
    }
  }

  &:disabled {
    background-color: $gray3;
    color: $gray5;
    cursor: not-allowed;

    .icon {
      svg {
        stroke: $gray5;
        fill: $gray5;

        path {
          fill: $gray5;
        }
      }
    }

    &.ghost {
      border-color: $gray4;

      &:hover {
        background-color: $gray4;
        border-color: $gray4;

        .icon {
          svg {
            stroke: $gray5;
            fill: $gray5;
          }
        }
      }
    }

    &.danger {
      background-color: color.scale($red, $saturation: -50%);

      &:hover {
        background-color: color.scale($red, $saturation: -50%);
      }
    }

    &:active {
      transform: none;
      background-color: $gray4;
    }
  }
}

.btn-icon {
  background-color: transparent;
  border: solid 1px $gray2;
  cursor: pointer;
  padding: 0.4rem;
  border-radius: 50%;
  transition: all 0.2s;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    stroke: $gray5;
    strokeWidth: 1;
  }

  &:hover {
    transform: scale(1.1);
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.07);
  }

  &:active {
    transform: scale(1.05);
  }

  &.red {
    color: $red;
    border-color: rgb(240, 188, 188);

    svg {
      stroke: $red;
    }
  }
}
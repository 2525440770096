@use "sass:color";
@use "src/styles/vars" as *;
@use "src/styles/mixins" as *;

.body {
  background-color: $gray;
  padding: 1rem 2rem 5rem 2rem;
  overflow-x: auto;

  @include for-phone-only {
    padding: 1.5rem 1rem 3rem 1rem;
  }

  & > div {
    margin: 0px auto;
    max-width: $max-page-width;
  }
}

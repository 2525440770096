@use "vars" as *;

@mixin for-phone-only {
  @media (max-width: 599px), (max-height: 600px) {
    @content;
  }
}

@mixin focus {
  outline: 1px solid $primary;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.75);
  transition: none;
}

// Applies focus style on keyboard generated focus events only.
@mixin keyboard-focus-only {
  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:focus-visible {
    @include focus;
  }
}

@use "sass:color";
@use "src/styles/vars" as *;

.input {
  color: $gray6;
  height: 2.2rem;
  padding: 0rem 0.5rem;
  border: 1px solid $gray2;
  border-radius: 3px;

  &.sm {
    padding: 0rem 0.5rem;
    font-size: 0.9rem;
    height: 1.8rem;
  }

  &.hasError {
    border-color: $red;
  }

  &::placeholder {
    color: $gray2;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
}

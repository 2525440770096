@use "sass:color";
@use "vars" as *;
@use "mixins" as *;
@use "fonts" as *;
@use "animations" as *;
@use "transitions" as *;
@use "defaults";
@use "button";

html {
    font-size: 16px;
}

body {
    color: $font-color;
    background-color: $gray6;
    font-weight: 300;
}

a,
a:active {
    color: $primary;
    text-decoration: none;
    font-weight: 300;
}

a:hover {
    color: $primary;
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "JLREmeric", $font-fallback;
    font-weight: 500;
    text-transform: uppercase;
    color: $gray5;
}

h1 {
    font-size: 2.7rem;
}

h2 {
    font-size: 2.5rem;
}

h3 {
    font-size: 2rem;
}

h4 {
    font-size: 1.7rem;
}

h5 {
    font-size: 1.5rem;
}

:focus {
    @include focus;
}

a,
button {
    @include keyboard-focus-only;
}

hr {
    margin: 0.75rem 0;
    border: 0;
    height: 1px;
    background: $gray3;
}

.pointer {
    cursor: pointer;
}

.error-text {
    color: $red;
    font-size: 0.75rem;
}

.desc {
    font-size: 0.8rem;
    color: $gray4;
    font-weight: 300;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.red {
    color: $red;

    &:a {
        color: $red;
        font-weight: bold;
        text-decoration: underline;
    }
}

a {
    &.red {
        color: $red;
        font-weight: bold;
        text-decoration: underline;
    }
}
@use "src/styles/vars" as *;
@use "src/styles/mixins" as *;
@use "src/styles/animations" as *;

.navMenu {
  position: relative;
  background-color: $gray;
  color: $gray4;
  width: 100%;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);

  .inner {
    display: flex;
    align-items: center;
    height: 3.7rem;
    max-width: $max-page-width;
    margin: 0 auto;

    @include for-phone-only {
      padding: 0 1rem;
    }
  }

  .navLogo {
    margin-top: 6px;
    width: 2.2rem;
    opacity: 1;
  }

  ul {
    list-style: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;

    li {
      margin-right: 1px;
      a {
        height: 3.7rem;
        display: flex;
        align-items: center;
        align-content: center;
        font-size: 0.8rem;
        font-weight: 500;
        transition: background-color 0.25s;
        padding: 0rem 0.5rem;
        margin: 0rem 0.5rem;
        color: $gray5;

        &:hover {
          text-decoration: none;
        }

        &.active {
          background-color: $gray;
          text-decoration: none;
          border-bottom: solid 2px rgb(235, 101, 35);
          margin-bottom: -2px;
        }
      }

      &.logOut {
        // Pushed Logout button all the way to the right
        margin: 0;
        margin-left: auto;
      }
    }
  }
}

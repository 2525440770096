@use "sass:color";
@use "src/styles/vars" as *;
@use "src/styles/animations" as *;

.container {
  position: fixed;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 1000;
  display: flex;
  flex-direction: column-reverse;
  gap: 0.5rem;
  transition: all 1s;
  font-size: 0.9rem;

  .notification {
    @include slide-in-animation(100px, $direction: "horizontal", $duration: 0.5s);
    width: 350px;
    padding: 0.5rem;
    background: $gray;
    position: relative;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.2);

    & > div {
      padding: 0.7rem;
      border-left: solid 4px $primary;

      .title {
        font-size: 1rem;
        display: flex;
        gap: 0.2rem;
        align-items: center;
      }
    }

    &.error {
      & > div {
        border-left-color: $red;
        .title {
          color: $red;
        }
      }
    }

    &.success {
      & > div {
        border-left-color: $green;
        .title {
          color: $green;
        }
      }
    }

    .close {
      border: none;
      cursor: pointer;
      opacity: 0.6;
      transition: opacity 0.2s;
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      background-color: transparent;

      &:hover {
        opacity: 0.9;
      }

      svg {
        width: 1.8rem;
        height: 1.8rem;
      }
    }
  }
}

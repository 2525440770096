@use "sass:string";

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) forwards;
  animation-delay: 0.5s;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-2px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-6px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(5px, 0, 0);
  }
}

@mixin slide-in-animation($from, $direction: "vertical", $duration: 0.5s, $fill-mode: none) {
  $transform: translateY;
  @if $direction == "horizontal" {
    $transform: translateX;
  }

  $keyframeName: unique-id();

  // Only works if @keyframe has unique name.
  @keyframes #{$keyframeName} {
    from {
      opacity: 0;
      transform: #{$transform }(#{$from}); // e.g transform: translateY(100px);
    }
    to {
      opacity: 1;
      transform: #{$transform }(0px);
    }
  }

  animation: $keyframeName $duration $fill-mode;
}

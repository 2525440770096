@use "sass:color";

$primary: #716c64;
$secondary: #262422;

$white: #fff;
$black: #262422;

$highlight: #8cbcc7;

$lightGray: #f6f5f4;
$gray: #f2efed;
$gray2: #d6d2cb;
$gray3: #bab1a5;
$gray4: #716c64;
$gray5: #4c4843;
$gray6: #262422;

$red: #ff4d4d;
$green: #52ca7f;
$amber: #fc9e27;

$font-color: $gray5;

$max-page-width: 1000px;
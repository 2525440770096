@use "src/styles/vars" as *;
@use "src/styles/mixins" as *;

.footer {
  width: 100%;
  // Makes teh footer stick to teh bottom of the page
  margin-top: auto;
  padding-bottom: 0.5rem;

  hr {
    background: $gray5;
  }

  & > div {
    color: $gray4;
    font-size: 0.9rem;
    margin: 3rem auto;
    max-width: $max-page-width;

    a {
      color: $gray4;
    }

    @include for-phone-only {
      padding: 0 1rem;
    }
  }
}

@use "sass:color";
@use "src/styles/vars" as *;

.container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  label {
    font-weight: 400;
    font-size: 12px;
  }

  .isRequired {
    color: $red;
  }
}